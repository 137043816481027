import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppService } from 'src/app/app.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import metaData from 'src/assets/data/default/header-footer.json';
import {config} from 'src/configs/config';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

	under_carriage: boolean = true;

	public mainMenuToggle = false;

	allCategories = [];
	dynamicMenuItems = {};

	allMenuItem = metaData.menu;
	config = config;
	categoryNameList = [];
	categoryListObj = [];
	categorysubmenu=[];

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private router: Router,
		private localService: LocalStorageService
	) { }



	ngOnInit(): void {
		this.getAllCategories();
	}

	navMainMenuToggle(): void {
		this.mainMenuToggle = !this.mainMenuToggle;
	}
	toggletNavActive(item,link) {
		if(config.title === "ShubhCards" || config.title === "Deera" || config.title === "BestGifts"){
			//  this.localService.set('is_theme_filter','true');
			// this.localService.set('theme_filter_name',item.name);
			
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[item.name],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
	
		}
		else
		if(config.title === "BombayHardware"){
			//  this.localService.set('is_theme_filter','true');
			// this.localService.set('theme_filter_name',item.name);
			
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[item.name],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
	
		}
		else if (config.title === "Nesma")
		{
			this.router.navigate([`/product-listing`], { queryParams: { terms: [item.name, 'Category'],menutype:link, type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });

		}
		else if (config.title === "BBold")
		{
			this.router.navigate([`/product`], {queryParams: { terms:[item.name, 'Category'], type:'searchresults' }, relativeTo:this.route,  skipLocationChange: false });

		}
		else
		{
			this.router.navigate([`/productListing`], { queryParams: { terms: [item.name], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
	
		}
			item.active = !item.active;
	}

	categoryListing(categoryName){
		this.router.navigate([`/productListing`], { queryParams: { terms: [categoryName, 'Category'], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
	  }

	toggletMainActive(item) {
		if(config.title === "Murgasen"){
			this.router.navigate([`/productListing`], {queryParams: {type: "last90days"}, relativeTo:this.route,  skipLocationChange: false });
		}
		else if(config.title === "ShubhCards" || config.title === "Deera" || config.title === "BestGifts"){
			this.localService.set('is_clear_data','true')
			console.log("datataa")
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
	
			
	
		}
		else if(config.title === "BombayHardware"){
			// this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });
			this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });

			
	
		}
		else{
			this.router.navigate([`/productListing`], { queryParams: { terms: [item.title], type: 'filter' }, relativeTo: this.route, skipLocationChange: false });
			item.active = !item.active;
		}

	}

	getAllCategories() {

		let reqData = {
			"domain_name": ORG_DETAILS.DOMAIN_NAME,
			"user_id": null,
			"extras": {
				"find": {
				},
				"pagination": false,
				"paginationDetails": {
					"limit": 1,
					"pagesize": 25
				},
				"sorting": true,
				"sortingDetails": {
					"sortfield": "sort_order",
					"sortorder": -1
				}
			}
		}
		this.appService.postApiCall(reqData, ENDPOINTS.GET_ALLCATEGORIES).subscribe(
			resp => {

				if(resp && resp.success == 1)
				{
					console.log(resp)
				this.allCategories = resp.result.data;
				this.categoryNameList = [];
	
				this.categoryListObj = [];
				let i = 0;
				let subchildren = [];
				let mainChildren = {}
				this.allCategories.forEach(ct => {
					this.categoryNameList.push(ct.name);
					this.categorysubmenu.push({"name":ct.name})
					if(i==0){
						mainChildren = {
							title: ct.name,
							type: 'sub',
							active: false
						}
						i = i + 1;
					}
					else{
						subchildren.push(
							{
								path: '',
								title: ct.name,
								type: 'link'
							}
						)
						if(i === 4){
							mainChildren["children"] = subchildren;
							this.categoryListObj.push(mainChildren);
							mainChildren = [];
							subchildren = [];
							i = 0
						}
						else{
							i = i + 1;
						}
					}
				});
				
				if(config.title === "Murgasen"){
					this.allMenuItem.forEach((amenu) => {
						if(amenu.title === "Categories"){
							amenu.children = this.categoryListObj
						}
					})
					console.log(this.allMenuItem)
				}
				if(config.title === "Nesma"){
					this.allMenuItem.forEach((amenu) => {
						if(amenu.title === "Menu"){
							amenu.children = this.categorysubmenu.filter(element=>
								
								!element.name.toLocaleLowerCase().includes('batter')
								

								
							
							
							)
						}
						if(amenu.title === "Batter"){
							amenu.children = this.categorysubmenu.filter(element=>
								
								element.name.toLocaleLowerCase().includes('batter')
								

								
							
							
							)
						}
					})
					console.log(this.allMenuItem)
				}


				this.allCategories.forEach((element)=>
				{
					if(config.title !== "Murgasen"&&config.title !== "Nesma"){
						this.allMenuItem.forEach((item)=>
					{
						if((item.title.toLocaleLowerCase())== element.name.toLocaleLowerCase())
						{
							console.log("category",element)
							item.children = element.subcategories
						}
					})
					}

				})
				}
			},
			err => {
				if (err.error.error.message) {
					console.log(err.error.error.message)
				}
				else {
					console.log('Something bad happened; Please try again!')
				}
			})
	}

	submenuFn(mtitle, stitle){

	}

	openLink(link, param)
	{

		if(config.title === 'Tams'){
			if(param['gotoPrepare']&& this.router.url == '/?gotoPrepare=true')
		{
			var elmnt = document.getElementById('how_to_prepare');
        	elmnt.scrollIntoView();
		}
		}
    console.log("link "+link)
   // console.log("param "+JSON.stringify(this.route))
   if(config.title==='Nesma')
   {
	
	   if(link=='/menu'||link=='/batter')
	   {
		this.router.navigate(['/product-listing'], {queryParams: {menutype:link}, relativeTo:this.route,  skipLocationChange: false });
	
	   }
	   else{
		this.router.navigate([link], {queryParams:param, relativeTo:this.route,  skipLocationChange: false });
	
	   }
		
	
	//this.router.navigate([`/product-listing`], { queryParams: { terms: ["",""], category: [], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
  
   }
   else{
	if(link==="/productListing" && config.title==="BombayHardware"){
		console.log("dataaa")
	    // this.router.navigate([`/productListing`], {queryParams: { terms:[[],['GI'],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	
		this.router.navigate([`/productListing`], {queryParams: { terms:[[],[],[],[],[],[],[],[],[],[],[],[]], type:'combine' }, relativeTo:this.route,  skipLocationChange: false });	

	} else {
		this.router.navigate([link], {queryParams: param, relativeTo:this.route,  skipLocationChange: false });
	}
   }
		
	}


	nosubCategory(category) {
		if(config.title === "Murgasen"){
			if(category === "productListing"){
				this.router.navigate([category], {queryParams: {type: "last90days"}, relativeTo:this.route,  skipLocationChange: false });
			}
			else{
				this.router.navigate([category])
			}
		}
		else{
			// this.router.navigate([category]);
			this.router.navigate(['/']).then(()=>{
			this.router.navigate([category]);
		})
		}

	}

}
