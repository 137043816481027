
    <!-- <div class="earthy-worthy-section earthy-worthy-menu"> -->
        <div class="container-fluid header sticky-header" *ngIf="configs.title != 'Aiema'">
            <header>
                <div class="inq-htop-st inq-row" id="banner" *ngIf="promotionBanner != '' && configs.title === 'Deera' || configs.title === 'BestGifts'">
                    <div class="text-center">
                        <p>
                            <span [innerHtml]="promotionBanner"></span>
                            <!-- <span>
                                <a href="#"><strong> More Details</strong></a>
                            </span> -->
                        </p>
                        <!-- <p>Get <strong>UPTO 40% OFF</strong> on your 1st order <span><a href="#"><strong> More Details</strong>
                                </a></span></p> -->
                        <span style="cursor: pointer;"><i class="fa fa-close" (click)='hideBanner()'></i></span>
                    </div>
                </div>
                <div class="flex-container" *ngIf="configs.title != 'BestGifts'">
                    <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer earth-worthy-logo" height="50" alt="Logo"
                                [routerLink]="['/']">
                    <div class="header-row-one">
                        <div class="search-bar-group" style="margin-right: 10px !important;">
                            <form [formGroup]="searchForm" class="search-bar">
                                <div class="d-flex justify-content-between search-input">
                                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                            <div *ngIf="configs.title!=='BombayHardware'" class="dropdown-menu autocomplete-items">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>                                      
                                            </div>
                                            <div *ngIf="configs.title==='BombayHardware'" class="dropdown-menu autocomplete-items">
                                                <div>
                                                    <a class="dropdown-item" (click)="navigateToList1('GI')"  style="cursor:pointer;font-size: 14px !important;">GI</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('GP')"  style="cursor:pointer;font-size: 14px !important;">GP</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('MS')"  style="cursor:pointer;font-size: 14px !important;">MS</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('RHS&SHS')"  style="cursor:pointer;font-size: 14px !important;">RHS&SHS</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Seamless')"  style="cursor:pointer;font-size: 14px !important;">Seamless</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('SpiralWeilded')"  style="cursor:pointer;font-size: 14px !important;">SpiralWeilded</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('UPVC')"  style="cursor:pointer;font-size: 14px !important;">UPVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('CPVC')"  style="cursor:pointer;font-size: 14px !important;">CPVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('PVC')"  style="cursor:pointer;font-size: 14px !important;">PVC</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('SWR')"  style="cursor:pointer;font-size: 14px !important;">SWR</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Flats')"  style="cursor:pointer;font-size: 14px !important;">Flats</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Angles')"  style="cursor:pointer;font-size: 14px !important;">Angles</a>
                                                    <a class="dropdown-item" (click)="navigateToList1('Pumps')"  style="cursor:pointer;font-size: 14px !important;">Pumps</a>
                                                </div>                                      
                                            </div>
                                          </div>
                                        <input type="text" placeholder="Search here."
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                        <div *ngIf="preList.length != 0">
                                            <div class="autocomplete-items1">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-btn">
                                            <button class="btn btn-solid" >
                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="account-section">
                            <ul class="account">
                                <!-- <li>
                                    <a href="javascript:;">
                                        <span class="lnr lnr-heart">
                                            <span class="count">
                                                <span class="count-number">0</span>
                                            </span>
                                        </span>
                                        <span class="menu-title">
                                            Wishlist
                                        </span>
                                    </a>
        
                                </li> -->
        
                                <!-- <li *ngIf="appService.userLoggedIn"> -->
                                    <!-- <a href="javascript:;">
                                                    <span class="lnr lnr-user">
                                                    </span>  
                                                    <span class="menu-title" (click)= "showLogout()">
                                                        My Account                                                
                                                    </span> -->
                                    
        
                                    <!-- </a> -->
                                <!-- </li> -->
                                <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:20px !important">
                                    <a href="javascript:;"  style="text-decoration: none !important;">
                                        <span class="fa fa-user" style="font-size:25px">
                                        </span> Login
                                    </a>
                                </li>
        
                                <li *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <a *ngIf= "configs.title !== 'BombayHardware'" class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                            <a *ngIf= "configs.title === 'BombayHardware'" class="logout-cutom dropdown-item" routerLink="enquiry-status">My Enquiry</a>

                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div>
                                </li>
        
                                <li class="header-cart account-popup">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title">
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                        </div>
        
                    </div>
        
                </div>

                <div class="flex-container" *ngIf="configs.title === 'BestGifts'">
                    <!-- <img [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer earth-worthy-logo" height="50" alt="Logo"
                                [routerLink]="['/']"> -->
                              
                    <div class="header-row-one">
                        <div class="search-bar-group" style="text-align: center;">
                            <img src="../../assets/images/bestgifts/Best Gifts.png" alt="Logo Image" class="logo-image custom-pointer earth-worthy-logo" height="50" alt="Logo"
                            [routerLink]="['/']">
                            <!-- <ul class="nav-menu">
                                <li>
                                  <a style="margin-left: 10px;">Home</a>
                                  <a style="margin-left: 10px;">Shop</a>
                                  <a style="margin-left: 10px;">Privacy Policy</a>
                                </li>
                          </ul> -->
                          <div class="inq-menu-st row justify-content-center">
                            <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                    data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                    class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                <div id="navbarNav" class="collapse navbar-collapse">
                                    <ul class="navbar-nav">
                                        <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                href="#/">HOME</a></li>
                                        <li class="header-cart account-popup">
                                            <a href="javascript:;" class="nav-link menu-text">SHOP</a>
                                                <app-mini-cart></app-mini-cart>
                                            </li>
                                        <li class="nav-item" id="about"><a class="nav-link menu-text" ng-reflect-router-link="about-us"
                                                href="#/about-us">ABOUT US</a></li>
                                        <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                href="#/contact-us">CONTACT US</a></li>
                                                
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        </div>
                        <div class="account-section">
                            <ul class="account">
                                
                                <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:20px !important">
                                    <a href="javascript:;"  style="text-decoration: none !important;">
                                        <span class="fa fa-user" style="font-size:25px">
                                        </span> Login
                                    </a>
                                </li>
        
                                <li *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                            
                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div>
                                </li>
        
                                <li class="header-cart account-popup">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title">
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                        </div>
        
                    </div>
        
                </div>
        
            </header>
        </div>
        <div class="container-fluid navbar-row" *ngIf="configs.title != 'Aiema' && configs.title != 'BestGifts'">
            <app-menu></app-menu>
        </div>
    <!-- </div> -->
    <div *ngIf="configs.title === 'Aiema'">
        <div class="earthy-worthy-section earthy-worthy-menu">
            <div class="container-fluid header sticky-header" >
                <header>
                    <div class="flex-container">
                        <img [src]="logoImage" alt="Logo Image" class="custom-pointer aiema-logo" height="50" alt="Logo" style="width: 72px!important; height: 63px!important;"
                                    [routerLink]="['/']">
                        <div class="header-row-one" style="width: 89%;">
                            <div class="search-bar-group" style="margin-right: 10px !important;display:contents;z-index:10; width:100%;">
                                <form [formGroup]="searchForm" class="search-bar" style=" width:100%">
                                    <div class="d-flex justify-content-between search-input" style="margin-right:25px">
                                        <!-- <p>Browse all <br><span>Categories</span></p> -->
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                                <div class="dropdown-menu autocomplete-items">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items1">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="account-section">
                                <ul class="account" style="margin-right:0px">
                                    <li *ngIf="!appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;font-size: 14px;font-weight: 500;"><span
                                                class="fa fa-user" style="font-size:25px; color: rgb(102, 102, 102);">
                                                </span>
                                                User/Member Login
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <a class="logout-cutom dropdown-item" routerLink="login-new">User Login/Register</a>
                                                <a class="logout-cutom dropdown-item" routerLink="member-register">Member Register</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li *ngIf="appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName }}
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                                <!-- <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a> -->
                                                <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                        </div>
            
                    </div>
            
                </header>
            </div>
            <div class="container-fluid navbar-row" style="padding-left: 0px !important;padding-right: 0px !important;">
                <app-menu></app-menu>
            </div>
        </div>
    </div>