import { Component, Input, OnChanges, OnInit, NgZone } from '@angular/core';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { Router, ActivatedRoute } from '@angular/router'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

import { AlertsService } from 'src/app/Components/alerts/alerts.service';
import { NavbarService } from 'src/app/Components/navbar/navbar.service';
import { DomSanitizer } from '@angular/platform-browser';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { AppService } from 'src/app/app.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { HeaderService } from './header.service';


import { config as menus }  from 'src/configs/config';

import {config as configs} from 'src/configs/config';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() item = ''; 
  ngOnChanges(){
    if(this.item=='/'){
      this.setClass('home')
    }
    else if(this.item=='/product'){
      this.setClass('menus')
    }
    else if(this.item=='/table-booking'){
      this.setClass('tableBooking')
    }
    else if(this.item=='/gift-meal'){
      this.setClass('giftAMeal')
    }
    else if(this.item=='/about-us'){
      this.setClass('about')
    }
    else if(this.item=='/contact-us'){
      this.setClass('contact')
    }
    else{
      this.setClass('')
    }
  }
  selectedValue=''
branches=[
  // {name:'Haven Sampoorna Velachery'},{name:'Haven Sampoorna Besant Nagar'}
]
  menus:any = menus.menu;
  config= menus;
  searchForm: UntypedFormGroup;
  preList = [];
  userLoggedIn: boolean = false;
  productOrList: boolean;
  productId: any;
  searchTerm: any;
  allCategories: any;
  promotionBanner: any;
  resultMessage: any;
  outlet1: any;
  outlet2: any;
  mobileNumber: any;
  showalertmsg: boolean = false;
  tamsProduct:any;
  tamsVideo:any;

  configs = configs;

  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

  constructor(config: NgbDropdownConfig,
    // private authService: AuthTokenService,
    private localService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    public appService: AppService,
    private formBuilder: UntypedFormBuilder,
    private headerService: HeaderService,
    private alertService: AlertsService,
    private navbarService: NavbarService,
    private sanitizer: DomSanitizer,
    ) {
    config.placement = 'bottom-right'
  }

  ngOnInit(): void {
    console.log("configgg"+this.config.title)
   
    this.getOutletData();
    this.getAllProducts();
    this.searchForm = new UntypedFormGroup({
      search_term: this.formBuilder.control('')
    })
    if (this.localService.get("UserName") != 'undefined' && this.localService.get("UserName")) {
      //The front-end should change when this is called.
      this.appService.userLoggedIn = true;
      this.appService.userName = this.localService.get("UserName");
    }
    else {
      this.appService.userLoggedIn = false;
      this.appService.userName = "";
    }
    this.navbarService.updateAdminFormEvent('clearSearchTerm');
    this.getPromotionBanner()
  }

  openLink()
	{
    if(configs.title === 'Tams'){
    //   if(this.router.url =='/' || this.router.url == '/?gotoPrepare=true')
    //   {
    //     var elmnt = document.getElementById('how_to_prepare');
    //     elmnt.scrollIntoView();
    //   }
    //   else
    // {
    //   this.router.navigate([''], {queryParams: {gotoPrepare : true}, relativeTo:this.route,  skipLocationChange: false });
    // }
    this.router.navigate(['knowily'],{skipLocationChange:false})
    }
    else
    {
      this.router.navigate([''], {queryParams: {gotoPrepare : true}, relativeTo:this.route,  skipLocationChange: false });
    }
	}

  getOutletData(){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
      resp => {
        if(resp.success === 1){
        
          this.outlet1=resp.result.data[0].name
          if(this.selectedValue=="" || this.selectedValue ==null){
            this.selectedValue=this.outlet1
            this.localService.set("ouletId",resp.result.data[0].id)
          }else{
          this.selectedValue=this.localService.get("outlet")
          }

          this.outlet2=resp.result.data[1].name
          this.branches.push(resp.result.data[0])
          this.branches.push(resp.result.data[1])
          this.mobileNumber=resp.result.data[0].phone
          this.localService.set('BranchName', JSON.stringify(resp.result.data[0].pickup_timings));
          
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }
  
  branchChange(i){
    console.log("phoneeee"+i.phone);
    this.mobileNumber = i.phone;
    this.localService.set("outlet",i.name)
    this.localService.set("outletId",i.id)
    this.selectedValue=this.localService.get("outlet")
    this.headerService.setCurrentScreen(i.id);
      }
    
  updateBranch(outlet){
    let requestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {
          name: "Haven Sampoorna Velachery"
        }
      }
    }
    this.appService.postApiCall(requestData, ENDPOINTS.GET_OUTLETS).subscribe(
      resp => {
        if(resp.success === 1){
          this.outlet2=this.outlet1
          this.outlet1=outlet
          this.localService.set('BranchName', JSON.stringify(resp.result.data[0].pickup_timings));
          this.mobileNumber=resp.result.data[0].phone
        }
        else{
          if(resp.message){
            this.alertService.error(resp.message, this.options)
          }          
          else{
            this.alertService.error('Something bad happened; Please try again!', this.options)
          }
        }          
      },
      err => {
        if (err.error.error.message) {
          this.alertService.error(err.error.error.message, this.options)
        }
        else{
          this.alertService.error('Something bad happened; Please try again!', this.options)
        }
      })
  }
  getPromotionBanner() {
    let respData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": ORG_DETAILS.USER_ID,
      "extras": {
        "find": {

        }
      }
    }
    this.appService.postApiCall(respData, ENDPOINTS.PROMOTIONS_BANNER).subscribe(
      resp => {
        console.log("res",resp)
        this.promotionBanner=resp.result.data[0].eligible_banner_message
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  getPreList() {
    let searchData = this.searchForm.value.search_term;
    if (searchData.length >= 2) {
      let reqData = {
        "domain_name": ORG_DETAILS.DOMAIN_NAME,
        "user_id": null,
        "extras": {
          "find": {
            "search": searchData
          }
        }
      }
      this.appService.postApiCall(reqData, ENDPOINTS.GET_PRELIST).subscribe(
        resp => {
          this.preList = resp.result.data;
        },
        err => {
          if (err.error.error.message) {
            console.log(err.error.error.message)
          }
          else {
            console.log('Something bad happened; Please try again!')
          }
        })
    }
    else {
      this.preList = [];
    }
  }

  savePreList(val) {
    this.sendProductDetails(val);
  }

  savePreList1() {
    var searchVal1 = this.searchForm.controls['search_term'].value;
    if (searchVal1) {
      this.sendProductDetails({
        "name": searchVal1,
        "type": ""
      });
    }
  }
  sendProductDetails(searchTerm) {
    let searchRequestData = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
          "search": searchTerm.name
        },
        "pagination": true,
        "paginationDetails": {
          "limit": 1,
          "pagesize": 35
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": 1
        }
      }
    }

    // MAKE AN API CALL
    this.appService.postApiCall(searchRequestData, ENDPOINTS.GET_SEARCH_RRESULTS).subscribe(
      resp => {
        let respData = resp.result.data;
        this.preList = [];
        if (respData.length === 1) {
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          // this.router.navigate([`/product`], {queryParams: { category:respData[0].name }, relativeTo:this.route,  skipLocationChange: false });
          this.router.navigate([`/product`], { queryParams: { terms: [searchTerm.name, searchTerm.type], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
        }
        else if (respData.length > 1) {
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate([`/product`], { queryParams: { terms: [searchTerm.name, searchTerm.type], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
        }
        else {
          this.searchForm.controls['search_term'].setValue(searchTerm.name);
          this.router.navigate([`/product`], { queryParams: { terms: [searchTerm.name, searchTerm.type], type: 'searchresults' }, relativeTo: this.route, skipLocationChange: false });
        }
      },
      err => {
        if (err.error.error.message) {
          console.log(err.error.error.message)
        }
        else {
          console.log('Something bad happened; Please try again!')
        }
      })
  }
  setClass(navLocation: string) {
    if(navLocation=='checkout' && this.appService.cartSubTotal==0){
      this.showalertmsg = true;
      this.alertService.error('Cart is empty, Please add a product to proceed', this.options)
    }
    else{
      if(navLocation=='checkout'){
        this.router.navigate(['/checkout'])
      }
    let listOfNavs = document.getElementsByClassName('nav-item');
    for (let i = 0; i < listOfNavs.length; i++) {
      listOfNavs[i].classList.remove('active');
    }
    if(document.getElementById(navLocation)!=null)
    document.getElementById(navLocation).classList.add("active");
    }
  }
  hideBanner(){
    document.getElementById("banner").style.display="none"
  }

 getAllProducts(){
   if(this.configs.title =='Tams'){
    var req_product_data = {
      "domain_name": ORG_DETAILS.DOMAIN_NAME,
      "user_id": null,
      "extras": {
        "find": {
        },
        "pagination": true,
        "paginationDetails": {
          "limit":(event),
          "pagesize": 20
        },
        "sorting": true,
        "sortingDetails": {
          "sortfield": "id",
          "sortorder": -1
        }
      }
    }
    this.appService.postApiCall(req_product_data, ENDPOINTS.GET_ALL_PRODUCTS).subscribe(
      resp => {
        let res:any = resp;
        if(res.success === 1){
          if(res.result.data.length > 0){
            this.tamsProduct = res.result.data[0];
          }
        }
      }
    )
   }
 }

 playVideo(video){
    this.tamsVideo = this.sanitizer.bypassSecurityTrustResourceUrl(video.link)
   document.getElementById('launch-video').click();
 }

 closeVideo(){
  var videos = document.querySelectorAll('iframe, video');
	Array.prototype.forEach.call(videos, function (video) {
		if (video.tagName.toLowerCase() === 'video') {
			video.pause();
		} else {
			var src = video.src;
			video.src = src;
		}
	});  
}

}
