import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MiniCartComponent } from "./mini-cart.component";

@NgModule ({
    
    declarations: [
        MiniCartComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        MiniCartComponent
    ]

})

export class MiniCartModule { };